import React from "react"
import { graphql, Link } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"

import { Heading } from "../components/Hero"
import { Section, Columns } from "../components/Containers"
import { Text } from "../components/Core"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faArrowLeft } from "@fortawesome/free-solid-svg-icons"

const BasicPage = ({ pageContext, location, data }) => {
  const { language } = pageContext
  const post = data.allBlogsJson.nodes[0]
  const queryPage = `/${data.queryPage.nodes[0].title}`
  return (
    <Layout language={language} className="single-blog">
      <SEO
        title={post.metaTitle}
        description={post.metaDescription}
        pathname={location.pathname}
        lang={language}
      />

      <Heading
        sideColumnsSize={4}
        heading={post.heading}
        blurb={`Posted on <time datetime="${post.published}">${new Date(
          post.published
        ).toLocaleDateString("en-us", {
          weekday: "long",
          year: "numeric",
          month: "long",
          day: "numeric"
        })}</time>`}
        boldBlurb
        htmlBefore={
          <Link className="go-back-button mb-1 block" to={queryPage}>
            <FontAwesomeIcon icon={faArrowLeft} />
            <span style={{ marginLeft: "5px" }}>BACK</span>
          </Link>
        }
      />

      <Section smallSpacing>
        <Columns sideColumnsSize={4}>
          <div className="column px-40--mobile">
            <Text text={post.body} />
          </div>
        </Columns>
      </Section>
    </Layout>
  )
}

export const basicPageQuery = graphql`
  query singleBlogQuery($title: String!) {
    allBlogsJson(filter: { title: { eq: $title } }) {
      nodes {
        metaTitle
        metaDescription
        heading
        body
        published
      }
    }
    queryPage: allUniquePagesJson(filter: { template: { eq: "blogs-query" } }) {
      nodes {
        title
      }
    }
  }
`

export default BasicPage
